<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-col cols="12" v-if="pageLoaded">
      <v-card-title
        style="background-color: transparent !important"
        class="ps-0 pe-0"
      >
        <v-row style="padding-left: 10px !important">
          <v-col class="text-right">
            <h3>لیست ویزیت‌های مجدد</h3>
          </v-col>
        </v-row>
      </v-card-title>
      <mobile-text
        v-if="deviceType == 'mobile'"
        :title="''"
      />
      <v-card class="cards pa-3" v-else>
        <v-card-text>
          <div class="px-2">
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              filled
              rounded
              single-line
              clearable
              hide-details
              class="text-right mb-2 search-input dr-search ms-0 me-0"
            ></v-text-field>
            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="ویزیتی برای نمایش وجود ندارد"
              empty-filtered-text="ویزیتی برای نمایش وجود ندارد"
              :busy="Busy"
              :filter="Filter"
              @filtered="onFiltered"
              :current-page="CurrentPage"
              :per-page="PerPage"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>
              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <div style="text-align: center; vertical-align: middle">
                  <v-btn
                    class="primary-btn py-2"
                    @click="
                      goToDoctorsList(
                        data.item.id,
                        data.item.otherDoctorExpertise,
                        data.item.patientId,
                        data.item.patientName
                      )
                    "
                    ><v-icon class="me-1"> mdi-calendar-question</v-icon>
                    تعیین زمان ملاقات
                  </v-btn>
                </div>
              </template>
              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>
<script>
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
const MobileText = () => import("@/components/MobileText");
export default {
  components: {
    datePicker: VuePersianDatetimePicker,
    MobileText
  },
  data() {
    return {
      CurrentPage: 1,
      oldCurrentPage: 1,
      oldPerPage: 10,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: false,
      isBusy: false,
      Fields: [
        { key: "index", label: "#" },
        { key: "patientName", label: "نام بیمار" },
        { key: "doctorName", label: "نام پزشک" },
        { key: "date", label: "تاریخ ویزیت" },
        { key: "otherDoctorExpertise", label: "تخصص" },
        { key: "operation", label: "عملیات " },
      ],
      Items: [],
      reactivity: false,
      roleFilter: "",
      pageLoaded: false,
    };
  },
  methods: {
    getVisits() {
      //connect to back
      this.Busy = true;
      this.vLoading = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/visits/needDoctor",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
            this.pageLoaded = true;
            this.vLoading = false;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.vLoading = false;
          }
        })
        .catch((err) => {
          this.Busy = false;
          this.vLoading = false;
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    goToDoctorsList(id, expertise, patientId, patientName) {
      localStorage.setItem("visitId", id);
      localStorage.setItem("visitExpertise", expertise);
      localStorage.setItem("patientId", patientId);
      localStorage.setItem("patientName", patientName);
      this.$router.push("/reception/ExpertiseDoctors/");
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    this.getVisits();
  },
};
</script>

<style lang="scss">
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 80%;
}
.modal-card {
  max-height: 580px;
  overflow-y: scroll;
}
.table-row {
  background-color: #b2dfdb91 !important;
}
</style>
